body {
    background-image: url("../images/background.PNG");
    background-color: black;
}

.removePadding {
    padding-left: 0px;
    margin: 0px;
}

.headerIcon:hover {
    color: rgb(242, 113, 28);
    font-weight: bold;
    animation: jiggle 500ms;
}

.headerIconText:hover {
    color: rgb(242, 113, 28);
    font-weight: bold;
    animation: jiggle 500ms;
}

.card {
    text-align: center;
}